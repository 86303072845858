<style lang="sass" scoped>
.container-login
  // background-color: #f8f9fa
  font-size: 12px

  .title
    font-weight: bold
    font-size: 1.5rem
    color: #555

  .form-control
    font-size: 16px
.btn-google
  filter: brightness(1)
  transition: filter 200ms ease-out
  &:hover
    cursor: pointer
    filter: brightness(0.95)
    transition: filter 200ms ease-out
    // box-shadow

</style>
<template lang="pug">
div.container-login
  .container(v-if='!session.id')
    .row.justify-content-center.align-items-center.async(style='height: 100vh' :class='{done:done}')
      .col-xl-5.col-lg-6.col-md-7
        h3.title.pb-3 노이텔러 로그인
        .mb-4
        form.form(@submit.prevent='login()')
          .form-group
            label.d-block 다음 계정으로 로그인
            //- a(v-if='!form.invitation' href='#' @click.prevent.stop="$modal.show('notice')")
              img.btn-google(src='/img/btn_google_signin_light_normal_web@2x.png' style='width: 191px')
            a(:href='`${ENV.VUE_APP_BASE_API_URL}/v2/connect/google`')
              img.btn-google(src='/img/btn_google_signin_light_normal_web@2x.png' style='width: 191px')
            .mb-4
            hr.opacity-50
            .mb-4
          .form-group
            label.d-block 이메일 주소
            input.form-control(type='text' v-model='form.email' required autofocus placeholder='Email address')
          .form-group
            label.d-block 비밀번호
            input.form-control(type='password' v-model='form.password' required placeholder='Password')

          .mb-3
            .float-left
              span.text-muted.mr-1 무료로 시작해보세요.
              router-link(to='/signup' custom v-slot="{href, navigate}")
                a.btn.btn-default.text-primary(:href='href' @click='track("클릭_회원가입", {}); navigate($event)') 회원가입
            .float-right
              button.btn.btn-primary(type='submit' @click='track("클릭_로그인")') 로그인
          .clearfix.mb-4
          .pb-4
          p.text-muted(style='font-size: 11px; letter-spacing: -1px; line-height: 18px')
            span 스튜디오인투 주식회사
            span.text-muted.mx-1(style='opacity:.3') |
            span 서울시 강남구 강남대로 122길 29-9 301
            span.text-muted.mx-1(style='opacity:.3') |
            span 대표 김지호
            br
            span 사업자등록번호 402-87-01962
            span.text-muted.mx-1(style='opacity:.3') |
            span Copyright © 2021 Studio INTO Inc. All rights reserved.

  .container.async(v-if='session.id' :class='{done:done}')
    .row.justify-content-center.align-items-center(style='height: 100vh')
      .col-xl-5.col-lg-6.col-md-7
        h3.title.pb-3 노이텔러에 로그인 되어있습니다.
        router-link(:to='{name: "workspace.list"}' custom v-slot="{href, navigate}")
          a(:href='href' @click='track("클릭_이메일"); navigate($event)').text-primary.btn.btn-light.btn-block.text-left.py-3 {{session.email}}
            b-icon-arrow-right.ml-2.float-right.text-secondary
            span.float-right.text-secondary Workspace
        //- h3.text-primary.btn.btn-light.btn-block.text-left {{session.email}}
          span.float-right.text-secondary 로그인 됨

        .mb-3
          router-link(to='/logout' custom v-slot="{href, navigate}")
            a.btn.btn-default.text-secondary(:href='href' @click='track("클릭_로그아웃"); navigate($event)') 계정 로그아웃
          //- router-link(to='/property').btn.btn-default.text-primary.float-right 나의 비즈니스 목록보기
  modal(name='notice' classes='vm--modal vue-dialog' :width='`375`' :height='`auto`' style='max-width: 375px' :clickToClose='true')
    button.btn.btn-default.float-right.text-muted.rounded-0.p-4(type='button' @click='$modal.hide("notice")' style='font-size: 1.5rem')
      b-icon-x
    .vue-dialog-content
      .vue-dialog-content-title 알림
      div.pt-3
        p 안녕하세요. 지금은 노이텔러 가입을 순차적으로 진행하고 있습니다. 따라서 초대링크를 통해서만 가입을 받고 있으며, 오른쪽 하단에 문의를 주시거나 waiting list에 등록해주시면 빠른 시일내에 연락드리겠습니다. 관심 가져 주셔서 감사합니다.
        br
        | 스튜디오인투 드림

        .py-3
          button.btn.btn-light.text-primary.btn-block(type='button' @click='input_code') 직접 코드 입력하기

</template>

<script>

import qs from 'querystring'

export default {
  name: 'index',
  components: {

  },
  computed: {
    session() {
      return this.$store.state.session
    },
  },
  mounted() {
    this.$store.dispatch('session')
    this.load()
    this.track('화면_진입')
  },
  data() {
    return {
      ENV: process.env,
      done: false,
      form: {},
    }
  },
  methods: {
    track(name, prop) {
      window.amplitude?.getInstance().logEvent(name, Object.assign({view:"로그인"}, prop))
    },
    input_code() {
      const code = prompt('초대코드를 입력해주세요.')
      if (!code) return

      location.href = `${this.ENV.VUE_APP_BASE_API_URL}/v2/connect/google?invitation=${code}`
    },
    async load() {
      try {
        const inv = this.$route.query.invitation
        if (inv) {
          this.form.invitation = inv
        } else {
          // this.$modal.show('notice')
        }
        // const r = await this.$http.get('/v1')
        // console.log('ping:', r?.data)

        const hash = String(this.$route.hash || '').slice(1)
        if (hash.length) {
          const q = qs.parse(hash)
          this.$modal.show('dialog', {
            title: '알림',
            text: q.result,
          })
          location.hash = '#'
        }

        setTimeout(() => {
          this.done = true
        }, 300);
      } catch (error) {
        console.log({error})
      }
    },
    async login() {
      try {
        const r = await this.$http.post('/v1/login/email', {
          ...this.form,
        })
        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '로그인 실패. 이메일 주소와 비밀번호를 확인해주세요.')

        const token = r.data.token

        window.localStorage.studio_token = token

        // await this.$store.dispatch('session')

        if (window.localStorage) {
          const property_subdomain = window.localStorage['noitaler.latest_property_subdomain']
          const uid = window.localStorage['noitaler.latest_user_id']
          if (property_subdomain && uid && +uid == +r.data.id) {
            this.track('이동_워크스페이스_최근')
            return this.$router.push({
              name: 'layout',
              params: {
                property_subdomain,
              }
            })
          }
        }
        this.track('이동_워크스페이스_목록')
        this.$router.push({
          name: 'workspace.list'
          // path: '/property',
          // query: {
          //   choose: 'Y',
          // }
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
  },
}
</script>
